@import './style.shared';

.economic-benefits-container {
  padding: 0 11.01%;

  @media (width <= 1023px) {
    padding: 50px;

    .economic-benefits--image {
      width: 350px;
      align-self: center;
    }
  }
}

.economic-benefits {
  max-width: 998px;
  height: 433px;
  margin: auto;
  justify-content: space-between;

  &--content {
    max-width: 45%;
    align-self: center;

    &-title {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 16px;
    }

    &-details {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

// @media (width <= 1023px) {
//   .economic-benefits {
//     height: 380px;

//     &--content {
//       max-width: 50%;
//     }
//   }
// }

@media (width <= 1023px) {
  .economic-benefits {
    flex-direction: column-reverse;
    max-width: 80%;
    height: auto;

    &--content {
      max-width: 90%;
      text-align: center;
      margin-bottom: 32px;
    }
  }
}

@media (width <= 500px) {
  .economic-benefits-container {
    padding: 60px 20px;

    .economic-benefits--content {
      max-width: 100%;
      text-align: center;
      margin-bottom: 32px;
    }

    .economic-benefits--image {
      width: 300px;
    }
  }
}
