$andes-theme: 'mercadopago';

@import './variables';

// Andes
@import '@andes/button/index';
@import '@andes/breadcrumb/index';
@import '@andes/form/index';
@import '@andes/textfield/index';
@import '@andes/common/index';
@import '@andes/dropdown/index';
@import '@andes/list/index';
@import '@andes/spinner/index';
@import '~@andes/checkbox/index';
@import '@andes/message/index';
@import '~@andes/card/index';
@import '~@andes/tag/index';
@import '~@andes/floating-menu/index';
@import '~@andes/floating-menu/base';
@import '~@andes/common/lib/styles/visually-hidden';
@import '~@andes/carousel-snapped/index';
@import '~@andes/radio-button/index';

// UI
// @import '~@landings-library/commons/styles/commons';

// partners navigations header and footer styles
@import 'partners-navigation/src/partners-navigation/styles/styles';

.partners-layout-container__content__with-warning main {
  padding: 30px 0 0 !important;
}

.background-rebranding {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;

  &--top {
    object-position: top;
  }
}

.login-button {
  color: $andes-blue-500;
}

.link {
  color: $andes-blue-500;
}
