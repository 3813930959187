.faq {
  &--title-container {
    text-align: center;
  }

  &--title {
    font-weight: 600;
    color: $andes-gray-900-solid;
    margin: 0;
  }

  &--action {
    text-align: center;
    margin-top: 32px;

    .andes-button__text + svg,
    svg + .andes-button__text {
      margin-left: 6px;
    }
  }

  &__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    margin-bottom: 32px;
  }

  .andes-button--transparent:hover {
    background-color: transparent;
    color: $andes-blue-500;
  }
}

.faq--item-container {
  border-bottom: 1px solid rgba($andes-black, 0.25);
  margin: auto;

  &--first {
    border-top: 1px solid rgba($andes-black, 0.25);
  }
}

.faq-item {
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &--title {
    display: flex;
    align-items: center;
    color: $andes-gray-900-solid;
  }

  &--response {
    align-items: baseline;
    color: $andes-gray-550-solid;
    display: none;

    &__opened {
      display: inline-block;
      margin-top: 16px;
    }
  }

  &--chevron__opened {
    transform: rotate(180deg);
  }
}
