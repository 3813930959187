.economic-benefits-container {
  position: relative;
  background-size: cover;
  background-position: center bottom;

  .andes-button--transparent path {
    stroke: $andes-blue-500 !important;
  }
}

.economic-benefits {
  display: flex;
  align-items: center;
  margin: auto;

  &--image {
    max-width: 384px;
  }

  &--content {
    &-title {
      margin: 0;
      color: $andes-gray-900;
      font-size: 28px;
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 31.5px;
    }

    &-details {
      color: $andes-gray-550;
      margin: 0;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
    }

    &-action {
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      .andes-button__text + svg,
      svg + .andes-button__text {
        margin-left: 6px;
      }
    }
  }

  .andes-button--transparent:hover {
    background-color: transparent;
    color: $andes-blue-500;
  }
}
