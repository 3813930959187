@import './style.shared';

.clients--benefits {
  &--container-title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 40px;
    margin-top: 20px;
    color: $andes-white;
  }

  &--container {
    justify-content: space-between;
    max-width: 1050px;

    &-image {
      max-width: 427px;
    }

    &-margin {
      max-width: 501px;
    }

    &-list {
      max-width: 50%;

      &-item-title {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 700;
        line-height: 20.3px;
      }

      &-item-details {
        font-size: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

@media (width <=1023px) {
  .clients--benefits {
    &--container {
      &-list {
        max-width: 80%;

        &-item {
          &-title {
            font-size: 18px;
            line-height: 22px;
          }

          &-details {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      &-image {
        width: 330px;
      }
    }
  }
}

@media (width <= 800px) {
  .clients--benefits {
    padding: 60px 32px 30px;

    &--container-title {
      text-align: center;
    }

    &-title {
      max-width: 100%;
    }

    &--container {
      flex-direction: column;

      &-list {
        max-width: 100%;
      }

      &-image {
        width: 300px;
      }
    }
  }
}
