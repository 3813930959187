@import './style.shared';

.faq {
  padding: 60px 4.6%;

  &--title-container {
    margin-bottom: 32px;
  }

  &--title {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin: 0;
  }
}

.faq--item-container {
  padding: 24px;
  max-width: 998px;
}

.faq-item {
  &--title {
    font-size: 18px;
    font-weight: 700;
    line-height: 20.25px;
  }

  &--response {
    font-size: 16px;
    line-height: 20px;
    padding-right: 24px;
  }
}
