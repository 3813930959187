@import './style.shared';

.partners-benefits {
  padding: 60px 9.14% 0 10.39%;

  &--container {
    max-width: 1018px;

    &-title {
      font-size: 28px;
      line-height: 35px;
      max-width: 58%;
      margin: auto;
    }

    &-section {
      margin-top: 50px;
    }

    &-img {
      margin-bottom: 32px;
    }
  }

  .width {
    width: 238px;
  }

  @media (width <= 1023px) {
    &--container-title {
      font-size: 24px;
      line-height: 28px;
      max-width: 90%;
    }
  }

  @media (width <= 700px) {
    &--container-title {
      max-width: 100%;
      font-size: 20px;
      font-weight: 700;
      line-height: 22.5px;
      text-align: center;
    }
  }

  @media (width <= 500px) {
    .width {
      width: 100%;
    }
  }
}

.partners-benefits__card {
  margin: 0 1.6% 60px 0;

  &--content {
    &-title {
      margin: 12px 0 4px;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
    }

    &-description {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .partners-benefits__card-img svg,
  img {
    width: 24px;
    height: 24px;
  }

  @media (width <= 1023px) {
    margin: 0 6.6% 60px 0;
  }

  @media (width <= 500px) {
    margin: 0 0 60px;

    &--content {
      &-title {
        margin: 6px 0 4px;
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
      }

      &-description {
        font-size: 18px;
        font-weight: 400;
        line-height: 22.5px;
        text-align: center;
      }
    }
  }
}
