.introduction {
  margin: auto;
  display: flex;

  &-content {
    &--title {
      font-weight: 600;
      margin: 0;
    }

    &--description {
      margin: 0;
      color: $andes-gray-550-solid;
    }
  }
}
