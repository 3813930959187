.s-info {
  background-color: $andes-white;

  .andes-carousel-snapped__container--content
    .andes-carousel-snapped__control--next {
    right: -80px;
  }

  .andes-carousel-snapped__container--content
    .andes-carousel-snapped__control--previous {
    left: -80px;
  }
}
