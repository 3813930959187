@import './style.shared';

.step-certification {
  &-main-container {
    padding: 80px 0;
    scroll-margin-top: 90px;
  }

  &-container {
    max-width: 998px;

    &-body {
      flex-direction: column;
      place-items: center;
    }
  }

  &-header {
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 40px;
  }
}

.container-lines {
  align-items: center;
  justify-content: space-between;
  width: 75%;

  .line {
    height: 1.5px;
    width: 40%;
    background-color: $andes-blue-500;
    margin: 0;
  }
}

.container-text {
  margin-top: 16px;
  max-width: 100%;

  .text-box {
    text-align: center;
    width: 33.37%;
    padding: 0 1.6%;
    margin: 0;
  }
}

.step-action {
  margin-top: 40px;
}

@media (width <=599px) {
  .step-certification-main-container {
    padding: 32px;
    background-color: $andes-gray-040-solid;
  }

  .step-certification {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      &-body {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &-header {
      text-align: center;
      font-weight: 600;
      color: $andes-gray-900-solid;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .container-lines {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 40px;
      margin-right: 10px;
    }

    .line {
      height: 1.5px;
      width: 60px;
      background-color: $andes-blue-500;
      margin: 0 10px;
    }
  }

  .container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;

    .text-box {
      color: $andes-gray-550-solid;
      font-size: 14px;
      line-height: 18px;
      width: auto;
      text-align: left;
      margin: 8px 0;
    }
  }

  .step-action {
    margin-top: 20px;
    height: auto;
    line-height: 36px;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
  }

  @media (width <= 1023px) {
    .container-lines {
      flex-direction: column;

      img {
        width: 40px;
        margin: 0;
      }

      .line {
        height: 60px;
        width: 1.5px;
      }
    }

    .container-text {
      .text-box {
        width: auto;
        margin-top: 8px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.5px;
      }
    }

    .step-action {
      font-size: 12px;
    }
  }
}
