@import '../../../../styles/common';
@import '../../../../styles/variables';

// Components
@import '../../../../components/developers/HeaderHero/style.desktop';
@import '../../../../components/developers/Footer/style.desktop';
@import '../../../../components/developers/Home/style.desktop';
@import '../../../../components/developers/Home/ClientsBenefits/style.desktop';
@import '../../../../components/developers/Home/Introduction/style.desktop';
@import '../../../../components/developers/Home/EconomicBenefits/style.desktop';
@import '../../../../components/developers/Home/Steps/style.desktop';
@import '../../../../components/developers/Home/Certifications/style.desktop';
@import '../../../../components/developers/Home/PartnersBenefits/style.desktop';
@import '../../../../components/developers/Home/FAQ/style.desktop';
@import '../../../../components/developers/Footnotes/style.desktop';

.home {
  background-color: $andes-white;
}
