.footnotes {
  margin: auto;

  &--disclaimer {
    color: $andes-gray-550-solid;
    margin: 0;
    font-size: 12px;
  }

  &--link {
    color: $andes-blue-500;
  }
}
