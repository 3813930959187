@import './style.shared';

.header-hero {
  padding: 60px 4.6%;

  @media (width <=768px) {
    padding: 60px 4.6%;
    height: 600px;
    background-position: unset;
  }
}

.header {
  max-width: 1050px;
  display: flex;
  justify-content: space-between;
  margin: auto;

  &--content {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 50%;

    &-program {
      margin-bottom: 8px;
    }

    &-title {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    &-details {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  @media (width <=1023px) {
    &--content {
      max-width: 100%;

      &-title {
        font-size: 32px;
        line-height: 30px;
      }

      &-details {
        font-size: 20px;
        line-height: 22px;
      }
    }

    &--image {
      width: 420px;
    }
  }

  @media (width <=768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--content {
      &-title {
        font-size: 28px;
        line-height: 28px;
      }

      &-details {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &--image {
      width: 290px;
    }
  }
}

@media (width <=600px) {
  .header-hero {
    height: 650px;
  }

  .header {
    &-hero {
      padding: 30px 20px;
    }
  }
}
