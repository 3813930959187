.certifications {
  &-title {
    font-weight: 600;
    text-align: center;
    margin: 0;
  }

  &--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-card {
      text-align: center;
      margin-bottom: 24px;
      border-radius: 18px;

      &-title {
        margin: 13px auto 6px;
        height: 24px;
        font-size: 24px;
        font-weight: 700;
        line-height: 27px;
        color: $andes-gray-550;
      }

      &-description {
        color: $andes-gray-550;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      .bold {
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        line-height: 20.3px;
        margin-bottom: 4px;
      }

      .line {
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: $andes-gray-070-solid;
        margin: 32px 0;
      }

      &-tag {
        display: flex;

        p {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: $andes-gray-550-solid;
          margin-bottom: 8px;
          margin-left: 6px;
        }
      }

      &-link {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        margin: 0;
        margin-top: 16px;
        width: 100%;

        .andes-button__text + svg,
        svg + .andes-button__text {
          margin-left: 6px;
        }
      }
    }
  }

  &-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }

  &--link {
    color: $andes-blue-500;
  }
}
