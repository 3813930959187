@import './style.shared';

.certifications {
  padding: 80px 11.09% 56px;
  scroll-margin-top: 90px;

  &-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 31.5px;
    text-align: center;
    margin-bottom: 40px;
  }

  &--content {
    gap: 60px;

    &-card {
      width: 323px;
    }
  }
}

@media (width <=690px) {
  .certifications--content-card {
    margin-right: 0;

    &-title {
      margin: 32px auto 6px;
      height: 24px;
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      color: $andes-gray-900-solid;
    }

    &-description {
      color: $andes-gray-900-solid;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
