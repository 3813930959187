.clients--benefits {
  position: relative;

  //background-image: url('/app/assets/images/developers/client/banner.svg');
  background-size: cover;
  padding: 120px 4.6%;

  &--container-title {
    color: $andes-white;
    font-size: 28px;
    font-weight: 700;
    line-height: 31.5px;
  }

  &--container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    margin: auto;
    color: $andes-white;

    &-list-item {
      display: flex;

      &-title {
        margin: 0;
        color: $andes-white;
        font-weight: 600;
        margin-left: 15px;
        margin-bottom: 8px;
      }

      &-details {
        color: $andes-white;
        margin: 0;
        margin-left: 15px;
      }

      .benefit--link {
        color: $andes-blue-500;
      }
    }
  }
}
