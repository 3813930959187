@import './style.shared';

.introduction {
  // margin-top: 85px;
  padding: 60px 20px;
  max-width: 998px;
  justify-content: space-between;
  align-items: center;

  &-content {
    max-width: 486px;
    margin-left: 16px;

    &--title {
      margin-bottom: 24px;
      font-size: 28px;
      font-weight: 700;
      line-height: 31.5px;
    }

    &--description {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
    }
  }

  &--video-iframe {
    width: 441px;
  }

  &--image-frame {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (width <=768px) {
    padding: 30px 20px;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

    &-content {
      margin-left: 0;

      &--title {
        font-size: 20px;
        font-weight: 700;
        line-height: 22.5px;
        text-align: center;
        margin: 24px 0 16px;
      }
    }

    &--image {
      width: 296px;
    }

    @media (width <=768px) {
      padding: 32px;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;

      &-content {
        margin-left: 0;

        &--title {
          font-size: 20px;
          font-weight: 700;
          line-height: 22.5px;
          text-align: center;
          margin: 24px 0 16px;
        }

        &--description {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &--video-iframe {
        width: 100%;
        max-width: 441px;
        height: auto;
        aspect-ratio: 16/9;
        box-sizing: border-box;
        padding: 0;
        margin: 0 auto;
      }

      &--image-frame {
        display: flex;
        width: 100%;
        max-width: 441px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
