.step-certification-main-container {
  background-color: $andes-gray-040-solid;
}

.container-lines {
  display: flex;
}

.step-certification {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &-body {
      display: flex;
    }
  }

  &-img {
    margin-bottom: 32px;
  }

  &-header {
    text-align: center;
    font-weight: 600;
    color: $andes-gray-900-solid;
  }
}

.container-text {
  display: flex;

  .text-box {
    font-size: 16px;
    line-height: 20px;
    color: $andes-gray-550-solid;
  }
}
